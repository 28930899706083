.Header{
    background-color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.Header1{
    height:80px;
    background-color:white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: 0.3s;
}
.logo{
    margin-left:10em ;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Navs{
    
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-right: 10em;
    transition: 0.3s;
}
.Navs >a{
    transition: 0.3s ease-in;
    text-decoration: none;
    color: black;
    padding: 7px 15px;
    margin: 0 5px;
}
.Navs >a:hover{
    background-color: rgb(92, 90, 90);
    color: white;
    transition: 0.3s;
}
.Navs >a.active{
  background-color: black;
    color: white;
    padding: 7px 15px;
    transition: 0.3s;
    border-radius: 5px;
}
.slo{
    height: 70px;
}
@media screen and (width<700px) {
    .logo{
       margin-left:4em;
       transition: 0.3s;
       
    }
    .Navs{
        margin-right: 4em;
    }
}