.App {
  text-align: center;
}
.News{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
a{
  text-decoration: none;
  color: black;
}
