.loading{
    height:100%;
    width: 100%;
    background-color:white;
    display: flex;
    align-items:center ;
    justify-content: center;
    position: absolute;
    z-index: 10;
    top: 0;
   flex-direction: column;
}


.main-item {
    padding: 10px;
    background-color: #fff;
    width: 90%;
    overflow: hidden;
  }
  
  .background-masker {
    background-color: #fff;
    position: absolute;
  }
  
  .btn-divide-left {
    top: 0;
    left: 30%;
    height: 100%;
    width: 100%;
  }
  .btn-divide-left1{
    top: 0;
    left: 45%;
    height: 100%;
    width: 10%;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }
  
  .animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 250px;
    position: relative;
  }
  .animated-background1 {
    margin-top: 10px;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 30px;
    position: relative;
  }
  .animated-background2 {
    margin-top: 10px;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 70px;
    position: relative;
  }
  .static-background {
    background-color: #f6f7f8;
    background-size: 800px 104px;
    height: 40px;
    position: relative;
    margin-bottom: 20px;
  }
  

  .pure-background {
    background-color: #eee;
  }
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  
  