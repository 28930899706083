{/*.Politicalpage{
//height: 100vh;
display: flex;
align-items: baseline;
justify-content: center;
//align-content: stretch;
margin-left: 10em;
margin-right: 10em;
}*/}
.Politicalpage{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    justify-items: left;
    flex-wrap: wrap;
    height: 100%;
    margin: 10px 20px;
    padding: 20px 0;
    row-gap: 2em;
}